<template>
  <div class="main-box">
    <el-form class="form-inline mg-tp-10" :inline="true" size="small">
      <el-form-item label="">
        <el-select v-model="search.order_type" placeholder="请选择订单类型" clearable>
          <el-option
              v-for="item in orderTypeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入订单号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="支付时间">
        <el-date-picker
            size="small"
            v-model="dateRange2"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="member_id"
          label="付款用户"
          align="center"
          width="240">
        <template slot-scope="scope">
          <div v-if="scope.row.member" class="member-box">
            <img :src="scope.row.member.avatar" />
            <div>
              <div>{{scope.row.member.name}}（{{scope.row.member.user_id}}）</div>
              <div>{{scope.row.member.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="order_sn"
          label="交易类型"
          align="center"
          width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.order_type==1">商城订单</div>
          <div v-if="scope.row.order_type==3">VIP订单</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="payment_sn"
          label="交易单号"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="amount"
          label="交易金额"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="trade_sn"
          label="第三方单号"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="order_sn"
          label="业务单号"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="下单时间"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="pay_time"
          label="支付时间"
          align="center"
          width="140">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      isOpen: false,
      dialogVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        order_type: '',
        delivery_type: '',
        status: 0,
        order_goods_type: '',
        refund: '',
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
      itemInfo: false,
      delivery: {
        order_id: 0
      },
      dateRange: ['',''],
      dateRange2: ['',''],
      orderTypeList: [
        {id:1,title:'商城订单'},
        {id:3,title:'购买VIP'},
      ]
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        order_type: that.search.order_type,
        order_goods_type: that.search.order_goods_type,
        delivery_type: that.search.delivery_type,
        refund: that.search.refund
      }
      if(this.dateRange) {
        param.start_time = this.dateRange[0]
        param.end_time = this.dateRange[1]
      }
      if(this.dateRange2) {
        param.pay_start_time = this.dateRange2[0]
        param.pay_end_time = this.dateRange2[1]
      }
      this.$api.order.orderTrade(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    },
  }
};
</script>
<style scoped>
.gdtable tr {
  background-color: unset;
  border-bottom: 1px solid #f5f5f5
}
.gdtable tr:last-child {
  border-bottom: none !important;
}
.gdtable td {
  padding: 5px 0;
}
.member-box {
  display: flex;
  align-items: center;
  text-align: left;
}
.member-box img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
